import { createApp as createClientApp } from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import { createHead } from '@vueuse/head';
import { createPinia } from 'pinia';
import { createRouter } from './router';
import sentinelApp from './VueApp.vue';
import './styles';
import axios from 'axios';
import { useStorage } from '@vueuse/core';
// import print from 'vue3-print-nb';

const defaultLocale = useStorage('locale', 'en' || 'en');
jwtInterceptor();
if (defaultLocale.value == 'ar') {
    document.querySelector('html').dir = 'rtl';
} else {
    document.querySelector('html').dir = 'ltr';
}



const plugins = import.meta.glob('./plugins/*.ts');
const app = createClientApp(sentinelApp);
app.use(VueSweetalert2);

export type sentinelAppContext = Awaited<ReturnType<typeof createApp>>
export type VueroPlugin = (sentinel: sentinelAppContext) => void | Promise<void>

export function definePlugin(plugin: VueroPlugin) {
    return plugin;
}

export async function createApp() {
    const app = createClientApp(sentinelApp);
    const router = createRouter();

    const head = createHead();
    app.use(head);

    const pinia = createPinia();
    app.use(pinia);

    const sentinel = {
        app,
        router,
        head,
        pinia,
    };

    app.provide('sentinel', sentinel);

    for (const path in plugins) {
        try {
            const { default: plugin } = await plugins[path]();
            await plugin(sentinel);
        } catch (error) {
            console.error(`Error while loading plugin "${path}".`);
            console.error(error);
        }
    }

    app.use(sentinel.router);

    // app.use(print);

    return sentinel;
}

function redirect(url: any) {
    const language = localStorage.getItem('locale');
    localStorage.clear();
    localStorage.setItem('locale', language);
    window.location.href = url;
}

function relativeRedirect(relativePath: any) {
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split(currentUrl);
    if (!currentUrl.includes('/login')) { redirect(urlParts[0] + relativePath + urlParts[1]); }
}


export function jwtInterceptor() {
    axios.interceptors.response.use(
        response => response,
        (error) => {
            if (error?.response?.status == 401) {
                relativeRedirect('/login');
            }
            return Promise.reject(error);
        },
    );
}
